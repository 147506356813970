<template>
    <v-container>
        <v-card>
            <v-card-title>
                Criar Tipo de Usuario

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>

                <v-btn
                    color="indigo darken-1"
                    dark
                    fab
                    small
                    :to="{name: 'userType.read'}"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-alert
                    v-model="alert.show"
                    dismissible
                    :color="alert.type"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alert.text}}
                </v-alert>

                <v-alert
                    v-model="alertSuccess.show"
                    dismissible
                    :color="alert.type"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alertSuccess.text}}
                </v-alert>

                <v-form ref="form" >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Descrição"
                                v-model="form.descricao"
                                counter
                                maxlength="64"
                                :rules="[
                                    form_descricao_rules.required, form_descricao_rules.counter,
                                        form_descricao_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="indigo darken-1"
                    dark
                   @click.prevent="save()"
                >
                    <v-icon>
                        mdi-save
                    </v-icon>

                    Salvar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="pink darken-1"
                    dark
                    @click.prevent="returnRead()"
                >
                    <v-icon>
                        mdi-block
                    </v-icon>

                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import router from '../../router'
export default {
    name: 'vwuserTypeCreate',
    data(){
        return {
            overlay: false,
            show1: false,
            form: {
                descricao: ''
            },
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },

        returnRead: function() {
            router.push({ name: 'userType.read' })
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/user-type/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o tipo de usuario!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/user-type/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Tipo de Usuario cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o tipo de usuario!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/user-type/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.overlay = false
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }
    }
}
</script>